import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const defaultProps = {};

type WithTranslationFormErrorsViewProps = {
  children: ReactNode;
  errors: unknown;
  setFieldTouched: () => void;
  touched: unknown;
} & typeof defaultProps;

const useTranslateFormErrors = (errors, touched, setFieldTouched) => {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.on(`languageChanged`, () => {
      Object.keys(errors).forEach((fieldName) => {
        if (Object.keys(touched).includes(fieldName)) {
          setFieldTouched(fieldName);
        }
      });
    });

    return () => {
      i18n.off(`languageChanged`, () => {});
    };
    // Disable the exhaustive dependency of `touched` and `setFieldTouched`
    // because we only want to re-translate when errors changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
};

export function WithTranslationFormErrorsView({
  children,
  errors,
  setFieldTouched,
  touched,
}: WithTranslationFormErrorsViewProps) {
  useTranslateFormErrors(errors, touched, setFieldTouched);
  return <>{children}</>;
}

WithTranslationFormErrorsView.defaultProps = defaultProps;

export default WithTranslationFormErrorsView;
