import { ReactNode } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';

import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';

import WithTranslationFormErrors from '@/components/core/WithTranslationFormErrors';

const defaultProps = {};

type FormBuilderViewProps = {
  controls: ReactNode;
  fieldOptions: Object;
  fieldTypes: Object;
  fields: Array;
  initialValues: Object;
  onSubmit: Function;
  validationSchema: Object;
} & typeof defaultProps;

const defaultFieldTypes = {
  default: {
    component: TextField,
    props: {
      fullWidth: true,
      InputProps: {
        disableUnderline: true,
        size: `medium`,
        type: `text`,
      },
      InputLabelProps: {
        shrink: true,
      },
      variant: `filled`,
    },
    setValueByDefault: true,
    type: `text`,
  },
  file: {
    component: TextField,
    props: {
      fullWidth: true,
      InputProps: {
        disableUnderline: true,
        size: `medium`,
        type: `file`,
      },
      InputLabelProps: {
        shrink: true,
      },
      variant: `filled`,
    },
    setValueByDefault: false,
    type: `file`,
  },
};

export function FormBuilderView({
  controls,
  fieldOptions = {},
  fieldTypes = {},
  fields = [],
  initialValues = {},
  onSubmit,
  validationSchema,
}: FormBuilderViewProps) {
  const formik = useFormik({
    ...(initialValues ? { initialValues } : {}),
    ...(onSubmit ? { onSubmit } : {}),
    ...(validationSchema ? { validationSchema } : {}),
  });
  const { t } = useTranslation();
  const localFieldTypes = {
    ...defaultFieldTypes,
    ...fieldTypes,
  };
  return (
    <Box>
      <WithTranslationFormErrors
        errors={formik.errors}
        touched={formik.touched}
        setFieldTouched={formik.setFieldTouched}
      >
        <form onSubmit={formik.handleSubmit}>
          {fields && fields.length && (
            <Box mb={6}>
              {fields.map(({ key, label, type }) => {
                const localField =
                  localFieldTypes[type] ?? localFieldTypes.default;
                const FieldComponent =
                  localField.component ?? localFieldTypes.default.component;
                const fieldOption = fieldOptions[key] ?? {};
                const fieldTouched = get(formik.touched, key);
                const fieldError = get(formik.errors, key);
                const fieldValue = get(formik.values, key);
                const hasSubmitted = formik.submitCount > 0;
                return (
                  <Box key={key} my={3}>
                    <FieldComponent
                      error={
                        (hasSubmitted || fieldTouched) && Boolean(fieldError)
                      }
                      id={key}
                      helperText={(hasSubmitted || fieldTouched) && fieldError}
                      label={label || t(fieldOption.i18n || key)}
                      onChange={formik.handleChange}
                      type={localField.type}
                      value={
                        localField.setValueByDefault ? fieldValue || `` : ``
                      }
                      {...(localField.props ?? {})}
                      {...(fieldOption.props ?? {})}
                    />
                    {!localField.setValueByDefault && fieldValue && (
                      <FormHelperText>{fieldValue.toString()}</FormHelperText>
                    )}
                  </Box>
                );
              })}
            </Box>
          )}
          <Box>{controls}</Box>
        </form>
      </WithTranslationFormErrors>
    </Box>
  );
}

FormBuilderView.defaultProps = defaultProps;

export default FormBuilderView;
