import { useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import AppFrame from '@/components/AppFrame';
import SitesCreate from '@/components/SitesCreate';

export function SitesCreateIndex({
  location,
  organizationId,
}: {
  location: any;
  organizationId: string;
}) {
  const [searchParams] = useState(new URLSearchParams(location.search));
  const [blueprintId] = useState(searchParams.get(`blueprint_id`));
  return (
    <AppFrame organizationId={organizationId}>
      <SitesCreate blueprintId={blueprintId} organizationId={organizationId} />
    </AppFrame>
  );
}

export default withAuthenticationRequired(SitesCreateIndex);
