import { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import { SelectChangeEvent } from '@mui/material/Select';

import SitesCreateView from '@/components/SitesCreate/SitesCreateView';
import { useApi } from '@/hooks/use-api';
import api from '@/utilities/api';

const defaultProps = {};

type SitesCreateContainerProps = {
  blueprintId?: string | null;
  organizationId: string | number;
} & typeof defaultProps;

export function SitesCreateContainer({
  blueprintId = ``,
  organizationId,
}: SitesCreateContainerProps) {
  const [selectedBlueprintId, setSelectedBlueprintId] = useState(blueprintId);
  const [getBlueprintParams, setGetBlueprintParams] = useState([
    organizationId,
    selectedBlueprintId,
  ]);
  const [getBlueprintOptions, setGetBlueprintOptions] = useState({
    isDisabled: !selectedBlueprintId,
  });
  const [createSiteParams, setCreateSiteParams] = useState([
    organizationId,
    {
      blueprintId: selectedBlueprintId,
      name: undefined,
      siteConfigData: undefined,
      subdomain: undefined,
    },
  ]);
  const [createSiteOptions, setCreateSiteOptions] = useState({
    isDisabled: true,
  });

  const {
    data: getBlueprintResponse,
    error: getBlueprintError,
    loading: getBlueprintLoading,
    refresh: refreshGetBlueprint,
  } = useApi(
    api.blueprints.get.bind(api.blueprints),
    getBlueprintParams,
    getBlueprintOptions,
  );

  const {
    data: createSiteResponse,
    error: createSiteError,
    loading: createSiteSubmitting,
    refresh: submitCreateSite,
  } = useApi(
    api.sites.post.bind(api.sites),
    createSiteParams,
    createSiteOptions,
  );

  const handleBlueprintChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;

    navigate(`?blueprint_id=${value}`);
    setSelectedBlueprintId(value);
    setGetBlueprintParams([organizationId, value]);
    setGetBlueprintOptions({
      ...getBlueprintOptions,
      isDisabled: false,
    });
    refreshGetBlueprint();
  };

  const handleBlueprintsChange = ({ data, loading }) => {
    if (!selectedBlueprintId && !loading && data?.blueprints[0]?.id) {
      handleBlueprintChange({ target: { value: data?.blueprints[0]?.id } });
    }
  };

  const handleSiteSubmit = ({ name, subdomain, ...siteConfigData }: any) => {
    setCreateSiteParams([
      organizationId,
      {
        blueprintId: selectedBlueprintId,
        name,
        siteConfigData,
        subdomain,
      },
    ]);
    setCreateSiteOptions({
      ...createSiteOptions,
      isDisabled: false,
    });
    submitCreateSite();
  };

  useEffect(() => {
    const newSiteId = createSiteResponse?.site?.id;

    if (newSiteId && !createSiteSubmitting && !createSiteError) {
      const timer = setTimeout(() => {
        navigate(`/organizations/${organizationId}/sites`, {
          state: {
            newSiteId,
          },
        });
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [createSiteResponse]);

  return (
    <SitesCreateView
      blueprint={getBlueprintResponse?.blueprint}
      blueprintError={getBlueprintError}
      blueprintId={selectedBlueprintId}
      blueprintLoading={getBlueprintLoading}
      createSiteData={createSiteResponse}
      createSiteError={createSiteError}
      createSiteSubmitting={createSiteSubmitting}
      onBlueprintChange={handleBlueprintChange}
      onBlueprintsChange={handleBlueprintsChange}
      onSiteSubmit={handleSiteSubmit}
      organizationId={organizationId}
    />
  );
}

SitesCreateContainer.defaultProps = defaultProps;

export default SitesCreateContainer;
