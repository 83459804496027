import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';
import convertToYup from 'json-schema-yup-transformer';

import EastIcon from '@mui/icons-material/East';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import UndrawLandingPage from '@/assets/svgs/undraw-landing-page.svg';
import FormBuilder from '@/components/core/FormBuilder';
import PageError from '@/components/core/PageError';
import PageLoading from '@/components/core/PageLoading';

// eslint-disable-next-line import/no-extraneous-dependencies
import { LandingBlueprint20211012Blueprint } from '@clients/landing-blueprint';

const defaultProps = {};

type SitesCreateViewProps = {
  blueprint: LandingBlueprint20211012Blueprint;
  blueprintError: any;
  blueprintId: string | number;
  blueprintLoading: boolean;
  createSiteData: any | null;
  createSiteError: any | null;
  createSiteSubmitting: boolean;
  onBlueprintChange: Function;
  onBlueprintsChange: Function;
  onSiteSubmit: Function;
  organizationId: string | number;
} & typeof defaultProps;

type FieldOption = {
  props: Object;
};

type FieldOptions = {
  [key: string]: FieldOption;
};

// This data is not included with 'fields' because sometimes we might want to mix
// ui-specific or even user-specific props, strings, or meta data to the field.
// Current this is not used for anything. The values of fieldOptions can be an
// empty object or not even exist.
const fieldOptions: FieldOptions = {
  title: {
    props: {},
  },
  description: {
    props: {},
  },
};

// This objects gets spread after the internal form builder's map.
// This is outside the scope of the component because it will only change when
// we add more custom field types like "Instagram.""
const fieldTypes = {};

export function SitesCreateView({
  blueprint,
  blueprintError,
  blueprintLoading,
  createSiteData,
  createSiteError,
  createSiteSubmitting,
  onSiteSubmit,
  organizationId,
}: SitesCreateViewProps) {
  const { t } = useTranslation();
  const [blueprintFormSchema, setBlueprintFormSchema] = useState({});

  useEffect(() => {
    if (blueprint && blueprint.schema) {
      setBlueprintFormSchema(convertToYup(blueprint.schema));
    }
  }, [blueprint]);

  return (
    <>
      <Box mb={6}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            component={GatsbyLink}
            to={`/organizations/${organizationId}`}
            underline="hover"
          >
            {organizationId}
          </Link>
          <Link
            color="inherit"
            component={GatsbyLink}
            to={`/organizations/${organizationId}/sites`}
            underline="hover"
          >
            {t(`sites.sites`)}
          </Link>
          <Typography color="text.primary">{t(`sites.create-site`)}</Typography>
        </Breadcrumbs>
        <Stack
          direction={{ xs: `row`, sm: `row` }}
          justifyContent="space-between"
        >
          <Typography
            variant="h1"
            color="inherit"
            sx={{ mb: { xs: 1, sm: 0 } }}
          >
            {t(`sites.create-site`)}
          </Typography>
        </Stack>
      </Box>

      {blueprintLoading ? (
        <PageLoading></PageLoading>
      ) : blueprintError ? (
        <PageError>{blueprintError.message}</PageError>
      ) : blueprint ? (
        <Grid container columnSpacing={{ xs: 4, sm: 4, md: 6 }}>
          <Grid item xs={6}>
            <Box component="section" mb={6}>
              <Box component="header" mb={2}>
                <Typography variant="h4" color="inherit">
                  {t(`sites.create.form.settings`)}
                </Typography>
                <Typography variant="body1" color="grey.900">
                  {t(`sites.create.form.settings-help`)}
                </Typography>
              </Box>
              <Box>
                <TextField
                  label="label"
                  helperText="help!"
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                    size: `medium`,
                    type: `text`,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
            <Box component="section" mb={6}>
              <Box component="header" mb={2}>
                <Typography variant="h4" color="inherit">
                  {t(`sites.create.form.configuration`)}
                </Typography>
                <Typography variant="body1" color="grey.900">
                  {t(`sites.create.form.configuration-help`)}
                </Typography>
                {createSiteError && (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    {createSiteData?.message}
                  </Alert>
                )}
              </Box>
              <FormBuilder
                controls={
                  <Stack spacing={1} direction="row">
                    <LoadingButton
                      color="primary"
                      disabled={createSiteSubmitting}
                      loading={createSiteSubmitting}
                      type="submit"
                      variant="contained"
                    >
                      {t(`sites.create-site`)}
                    </LoadingButton>
                    <Button
                      color="secondary"
                      component={GatsbyLink}
                      disableRipple
                      to={`/organizations/${organizationId}/sites`}
                      variant="text"
                    >
                      {t(`common.form.cancel`)}
                    </Button>
                  </Stack>
                }
                fields={blueprint?.fields}
                fieldTypes={fieldTypes}
                fieldOptions={fieldOptions}
                initialValues={blueprint?.data}
                onSubmit={onSiteSubmit}
                key={blueprint?.id}
                validationSchema={blueprintFormSchema}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Paper variant="outlined">
              <Box p={3}>
                <Box component="section">
                  <Box component="header">
                    <Typography variant="h3" color="inherit">
                      {blueprint?.name}
                    </Typography>
                    <Typography variant="subtitle1">
                      {blueprint?.description}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            maxWidth: 600,
            my: 2,
            mx: `auto`,
            textAlign: `center`,
          }}
        >
          <UndrawLandingPage height="250" width="250" />
          <Typography variant="h5" sx={{ mb: 1 }}>
            {t(`sites.create.blueprint-zero-state.to-get-started`)}
          </Typography>
          <Typography variant="body1" sx={{ color: `text.secondary`, mb: 2 }}>
            {t(`sites.create.blueprint-zero-state.blueprint-details`)}
          </Typography>
          <Box>
            <Button
              component={GatsbyLink}
              color="secondary"
              endIcon={<EastIcon />}
              to={`/organizations/${organizationId}/blueprints`}
            >
              {t(`sites.create.blueprint-zero-state.select-a-blueprint`)}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}

SitesCreateView.defaultProps = defaultProps;

export default SitesCreateView;
